<script>
import api from '@/services/api';
import PageTitle from '@/components/PageTitle';
import { BButton, BCol, BFormGroup, BFormInput, BModal, BRow } from 'bootstrap-vue';
import draggable from 'vuedraggable';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import TableActionBtns from '@/components/TableActionBtns';
import { checkLang, generateIconColorToBoolean, generateIconToBoolean } from '@/util/helper';

export default {
    name: 'VoteAnswers',
    components: {
        TableActionBtns,
        PageTitle,
        BButton,
        BModal,
        BCol,
        BRow,
        // BTable,
        BFormGroup,
        BFormInput,
        draggable
    },
    mixins: [CRUDPageMixin],
    data: () => ({
        question: {},
        answers: [],
        dataForModal: {
            name: {
                uz: '',
                ru: ''
            },
            hint: {
                uz: '',
                ru: ''
            },
            position: 0
        },
        organizationID: null,
        surveyID: null,
        questionID: null
    }),
    computed: {
        answersTableHeader() {
            return [
                {
                    key: '#',
                    label: ''
                },
                {
                    key: 'id',
                    label: 'ID'
                },
                {
                    key: 'name',
                    label: this.$t('titles.title')
                },
                {
                    key: 'hint',
                    label: this.$t('titles.hint')
                },
                {
                    key: 'position',
                    label: this.$t('titles.position')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },
        computedAnswers: {
            get() {
                return this.answers;
            },
            set(newValue) {
                this.answers = newValue;
            }
        }
    },
    methods: {
        checkLang,
        async updateWithData(item) {
            await this.getAnswer(item.id);
            this.openModalToUpdate(item);
        },
        async getQuestion() {
            const { data } = await api.questions.getQuestion(
                this.organizationID,
                this.surveyID,
                this.questionID
            );
            this.question = data.result;
        },
        async getOrganization() {
            const { data } = await api.organizations.getSingleOrganizationApi(this.organizationID);
            this.organization = data.result;
        },

        async getAnswer(id) {
            const { data } = await api.answers.getVoteAnswer(
                this.organizationID,
                this.surveyID,
                this.questionID,
                id
            );
            this.question = data.result;
        },

        async getAnswers() {
            const { data } = await api.answers.getVoteAnswers(
                this.organizationID,
                this.surveyID,
                this.questionID
            );
            this.answers = data.result;
        },

        async create() {
            const { data } = await api.answers.createVoteAnswer(
                this.organizationID,
                this.surveyID,
                this.questionID,
                { question_id: this.questionID, ...this.dataForModal }
            );
            this.answers.push(data.result);
            this.$toast.success(this.$t('success.added'));
        },

        async update() {
            const { data } = await api.answers.updateVoteAnswer(
                this.organizationID,
                this.surveyID,
                this.questionID,
                this.updatingItem.id,
                { question_id: this.questionID, ...this.dataForModal }
            );
            this.updateDataToNewData(data.result);
            this.$toast.success(this.$t('success.updated.title'));
        },

        async deleteAnswer(answerId, index) {
            try {
                await api.answers.deleteVoteAnswer(
                    this.organizationID,
                    this.surveyID,
                    this.questionID,
                    answerId
                );
                this.answers.splice(index, 1);
                this.$toast.success(this.$t('success.deleted'));
            } catch (e) {
                this.$toast.error(e.message);
            }
        },

        async updatePosition() {},

        openModalToCreate() {
            this.isOpenModal = true;
        },

        generateIconToBoolean,
        generateIconColorToBoolean
    },
    mounted() {
        this.organizationID = this.$route.params.organizationID;
        this.surveyID = this.$route.params.surveyID;
        this.questionID = this.$route.params.questionID;
        this.getAnswers();
        this.getQuestion();
    }
};
</script>

<template>
    <div>
        <page-title>
            <template #title>
                {{ $t('titles.question') }}: {{ checkLang(question.name) }}
            </template>
            <template #button>
                <b-button class="ml-auto" variant="success" @click="openModalToCreate">
                    {{ $t('add.answer') }}
                    <feather-icon icon="PlusIcon" />
                </b-button>
            </template>
        </page-title>

        <div class="card">
            <div class="informations-draggable-table">
                <table>
                    <thead>
                        <tr>
                            <th v-for="(title, index) in answersTableHeader" :key="index">
                                {{ title.label }}
                            </th>
                        </tr>
                    </thead>
                    <draggable
                        v-model="computedAnswers"
                        handle=".handle"
                        tag="tbody"
                        @change="updatePosition"
                    >
                        <tr v-for="(item, index) in computedAnswers" :key="index">
                            <td>
                                <div class="handle cursor-move">
                                    <feather-icon icon="AlignJustifyIcon" />
                                </div>
                            </td>
                            <td>
                                {{ item.id }}
                            </td>
                            <td>
                                {{ checkLang(item.name) }}
                            </td>

                            <td>
                                {{ checkLang(item.hint) }}
                            </td>

                            <td>
                                {{ item.position }}
                            </td>

                            <td>
                                <table-action-btns
                                    :delete-access="{ section: 'gifts', permission: 'delete' }"
                                    :delete-handler="deleteAnswer"
                                    :index="index"
                                    :item="item"
                                    :update-access="{ section: 'gifts', permission: 'update' }"
                                    @openModal="updateWithData"
                                />
                            </td>
                        </tr>
                    </draggable>
                </table>
            </div>
        </div>

        <b-modal
            v-model="isOpenModal"
            :hide-footer="false"
            :no-close-on-backdrop="true"
            :title="$t('add.survey')"
            cancel-variant="danger"
            centered
            size="xl"
            @hidden="onCloseModal"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-row>
                    <!--    ANSWER NAME UZ    -->
                    <b-col cols="12" lg="4" sm="6">
                        <label> {{ $t('titles.answer') }} (UZ) </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.answer')} UZ`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.uz"
                                    :placeholder="`${$t('enter.answer')} UZ`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    ANSWER NAME RU    -->
                    <b-col cols="12" lg="4" sm="6">
                        <label> {{ $t('titles.answer') }} (RU) </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.answer')} RU`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.ru"
                                    :placeholder="`${$t('enter.title')} RU`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    ANSWER NAME EN    -->
                    <b-col cols="12" lg="4" sm="6">
                        <label> {{ $t('titles.answer') }} (EN) </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.answer')} EN`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.en"
                                    :placeholder="`${$t('enter.title')} EN`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    ANSWER HINT UZ    -->
                    <b-col cols="12" lg="4" sm="6">
                        <label> {{ $t('titles.hint') }} (UZ) </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.hint')} UZ`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.hint.uz"
                                    :placeholder="`${$t('enter.hint')} UZ`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    ANSWER HINT RU    -->
                    <b-col cols="12" lg="4" sm="6">
                        <label> {{ $t('titles.hint') }} (RU) </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.hint')} RU`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.hint.ru"
                                    :placeholder="`${$t('enter.hint')} RU`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    ANSWER HINT EN    -->
                    <b-col cols="12" lg="4" sm="6">
                        <label> {{ $t('titles.hint') }} (EN) </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.hint')} EN`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.hint.en"
                                    :placeholder="`${$t('enter.hint')} EN`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    POSITION    -->
                    <b-col class="mb-1" cols="12" lg="3" sm="4">
                        <label>
                            {{ $t('titles.position') }}
                        </label>
                        <b-form-group>
                            <b-form-input
                                v-model="dataForModal.position"
                                :placeholder="$t('enter.position')"
                                size="lg"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </ValidationObserver>
            <template #modal-footer>
                <!--                <b-form-checkbox v-if="!updatingItem" v-model="createOneMore" class="mr-auto">-->
                <!--                    {{ $t('titles.add_more') }}-->
                <!--                </b-form-checkbox>-->
                <b-button variant="success" @click="modalSubmit">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<style scoped></style>
